import { Observable } from 'rxjs/Observable';
import superagent from 'superagent';
import result from 'lodash.result';
import retryOn from './utils/retryOn';

import './utils/rxDependencies';

const execute = (host, agent, settings, signal) => {
  const url = host + settings.uri;
  const options = settings.options || {};
  const query = settings.query || {};
  const data = settings.data || {};

  let request = agent[settings.method](url)
    .query(query)
    .send(data);
  if (options.json) request = request.serialize();
  if (options.headers) request = request.set(options.headers);
  if (options.withCredentials) request = request.withCredentials();

  signal.addEventListener('abort', () => {
    request.abort();
  });

  // below roughly comes from
  // https://github.com/KyleAMathews/superagent-bluebird-promise/blob/master/index.js#L75
  return new Promise((resolve, reject) => {
    let error;
    request.end((err, res) => {
      if (typeof res !== 'undefined' && res !== null && res.status >= 400) {
        const msg = `cannot ${request.method} ${request.url} (${res.status})`;
        error = new Error(msg);
        error.status = res.status;
        error.body = res.body;
        error.res = res;
        reject(error);
      } else if (err) {
        reject(error);
      } else {
        resolve(res);
      }
    });
  });
};

export const toObservable = promiseCreator => {
  return Observable.create(subscriber => {
    const controller = new AbortController();
    try {
      const promise = promiseCreator(controller.signal);
      promise
        .then(response => {
          subscriber.next(response);
          subscriber.complete();
          return null;
        })
        .catch(error => {
          subscriber.error(error);
          return null;
        });
    } catch (error) {
      subscriber.error(error);
    }
    return () => {
      controller.abort();
    };
  });
};

class MuxClient {
  constructor(
    host,
    retryOptions = { delay: 0, nbOfRetry: 0, enableHistory: false },
    multiplexerClientOptions = {}
  ) {
    this.host = host;
    this.retryOptions = retryOptions;
    this.listeners = {};
    this.multiplexerClientOptions = multiplexerClientOptions;
    // create a agent so that it will persist cookie between request
    this.agent = superagent.agent ? superagent.agent() : superagent;

    this.historyEnabled = retryOptions.enableHistory;
    this.history = [];
  }

  on(event, callback) {
    if (this.listeners[event]) this.listeners[event].push(callback);
    else this.listeners[event] = [callback];
  }

  off(event) {
    this.listeners[event] = [];
  }

  callListeners(event, data) {
    (this.listeners[event] || []).forEach(listener => {
      listener(data);
    });
  }

  requests(signal) {
    const endpoint = settings =>
      retryOn({
        fn: () =>
          execute(
            this.host,
            this.agent,
            {
              ...settings,
              options: {
                ...settings.options,
                headers: {
                  ...settings.options.headers,
                  ...this.multiplexerClientOptions.headers,
                },
              },
            },
            signal
          ),
        shouldRetry: error => result(error, 'status', 0) === 0,
        delay: this.retryOptions.delay,
        nbOfRetry: this.retryOptions.nbOfRetry,
      });

    return {
      new: (
        imageInfos,
        { email, authentication_token: authenticationToken, jwt }
      ) =>
        endpoint({
          method: 'post',
          uri: '/new',
          data: JSON.stringify({
            email,
            authentication_token: authenticationToken,
            jwt,
          }),
          query: imageInfos,
          options: {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'text/plain',
            },
            json: true,
            only2xx: true,
            withCredentials: true,
          },
        }).then(response => {
          this.authentication_token = authenticationToken; // eslint-disable-line camelcase
          this.sid = response.body.id;
          this.callListeners('new', response);
          if (this.historyEnabled) {
            this.history.push({ type: 'new' });
          }
          return response;
        }),
      input: ({ command, jwt }) =>
        endpoint({
          method: 'post',
          uri: '/input',
          data: JSON.stringify({
            command,
            // Use session jwt for authorization: https://github.com/datacamp-engineering/collab-and-tooling/blob/master/doc/adr/0002-use-jwt-for-authorization.md
            sessionJwt: jwt,
            authentication_token: this.authentication_token,
          }),
          query: { sid: this.sid },
          options: {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'text/plain',
            },
            json: true,
            only2xx: true,
            withCredentials: true,
          },
        }).then(res => {
          if (this.historyEnabled) {
            this.history.push({ type: 'input', command });
          }
          return res;
        }),
      read: () =>
        endpoint({
          method: 'post',
          uri: '/read',
          data: JSON.stringify({
            authentication_token: this.authentication_token,
          }),
          query: { sid: this.sid },
          options: {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'text/plain',
            },
            json: true,
            only2xx: true,
            withCredentials: true,
          },
        }).then(res => {
          if (this.historyEnabled) {
            this.history.push({ type: 'read' });
          }
          return res;
        }),
      flush: () =>
        endpoint({
          method: 'post',
          uri: '/flush',
          data: JSON.stringify({
            authentication_token: this.authentication_token,
          }),
          query: { sid: this.sid },
          options: {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'text/plain',
            },
            json: true,
            only2xx: true,
            withCredentials: true,
          },
        }).then(res => {
          if (this.historyEnabled) {
            this.history.push({ type: 'flush' });
          }
          return res;
        }),
      delete: () =>
        endpoint({
          method: 'post',
          uri: '/delete',
          data: JSON.stringify({
            authentication_token: this.authentication_token,
          }),
          query: { sid: this.sid },
          options: {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'text/plain',
            },
            json: true,
            only2xx: true,
            withCredentials: true,
          },
        }).then(() => {
          this.authentication_token = null;
          this.sid = null;
          if (this.historyEnabled) {
            this.history.push({ type: 'delete' });
          }
        }),
    };
  }
}

export default MuxClient;
